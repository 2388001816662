var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GmapMap',{staticStyle:{"width":"100%","height":"93vh"},attrs:{"center":_vm.gmapCenter,"zoom":7,"map-type-id":"terrain"}},[(_vm.poplyLineFlag)?_c('gmap-polyline',{ref:"polyline",attrs:{"path":_vm.deliveryPath,"options":{
      strokeWeight: 5,
      strokeColor: _vm.deliveryPathColor,
      strokeOpacity: 0.7,
      icons: [
        {
          icon: {
            path: _vm.figura_camino_polyline,
            scale: 3,
            fillColor: _vm.deliveryPathColor,
            fillOpacity: 1,
          },
          offset: '0',
          repeat: '100px',
        },
      ],
    }},on:{"click":function($event){_vm.poplyLineFlag = false}}}):_vm._e(),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoPosition,"opened":_vm.infoOpened},on:{"closeclick":function($event){_vm.infoOpened = false}}},[(_vm.infoContent)?_c('v-card',{},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.infoContent.consejera_nombre))]),_c('v-card-text',[_c('div',[_c('strong',[_vm._v("Visita:")]),_c('br'),_c('div',[_vm._v(" "+_vm._s(_vm.infoContent.motivo_de_visita)+" "),_c('v-icon',{class:_vm.fnGetColor(_vm.infoContent.motivo_de_visita_id)},[_vm._v("mdi-label-variant")])],1),_vm._v(" "+_vm._s(_vm.fnGetDate(_vm.infoContent.fecha))+" ")]),_c('div',[_c('strong',[_vm._v("Supervisora:")]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(_vm.infoContent.usuario_nombre)+" ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"title":"Visita previa","disabled":_vm.flagPrevVisita,"small":"","elevation":"1","text":"","color":"white"},on:{"click":function($event){return _vm.fnPrevVisita()}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-left")])],1),_c('v-btn',{attrs:{"title":"Siguiente visita","disabled":_vm.flagNextVisita,"small":"","elevation":"1","text":"","color":"white"},on:{"click":function($event){return _vm.fnNextVisita()}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-right")])],1),_c('v-btn',{attrs:{"target":"_blank","href":'/visita/' + _vm.infoContent.id,"text":"","color":"primary"}},[_vm._v(" Ver mas ")])],1)],1):_vm._e()],1),_vm._l((_vm.visitasDelDia),function(visita,index){return _c('GmapMarker',{key:index,attrs:{"id":'visita' + visita.id,"position":{ lat: visita.lat, lng: visita.lng },"clickable":true,"draggable":false,"icon":_vm.fnGetIcon(visita.motivo_de_visita_id)},on:{"click":function($event){_vm.toggleInfo(visita), (_vm.flagCurrentIndex = index)},"mouseover":function($event){return _vm.fnDrawPolyPath(index)}}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <GmapMap
    :center="gmapCenter"
    :zoom="7"
    map-type-id="terrain"
    style="width: 100%; height: 93vh"
  >
    <gmap-polyline
      v-if="poplyLineFlag"
      :path="deliveryPath"
      v-bind:options="{
        strokeWeight: 5,
        strokeColor: deliveryPathColor,
        strokeOpacity: 0.7,
        icons: [
          {
            icon: {
              path: figura_camino_polyline,
              scale: 3,
              fillColor: deliveryPathColor,
              fillOpacity: 1,
            },
            offset: '0',
            repeat: '100px',
          },
        ],
      }"
      ref="polyline"
      v-on:click="poplyLineFlag = false"
    ></gmap-polyline>
    <gmap-info-window
      :options="infoOptions"
      :position="infoPosition"
      :opened="infoOpened"
      @closeclick="infoOpened = false"
    >
      <v-card v-if="infoContent" class="">
        <v-card-title> {{ infoContent.consejera_nombre }}</v-card-title>
        <v-card-text>
          <div>
            <strong>Visita:</strong><br />
            <div>
              {{ infoContent.motivo_de_visita }}
              <v-icon :class="fnGetColor(infoContent.motivo_de_visita_id)"
                >mdi-label-variant</v-icon
              >
            </div>
            {{ fnGetDate(infoContent.fecha) }}
          </div>

          <div>
            <strong>Supervisora:</strong> <br />
            {{ infoContent.usuario_nombre }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            title="Visita previa"
            :disabled="flagPrevVisita"
            @click="fnPrevVisita()"
            small
            elevation="1"
            text
            color="white"
          >
            <v-icon color="primary">mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            title="Siguiente visita"
            :disabled="flagNextVisita"
            @click="fnNextVisita()"
            small
            elevation="1"
            text
            color="white"
          >
            <v-icon color="primary">mdi-arrow-right</v-icon>
          </v-btn>

          <v-btn
            target="_blank"
            :href="'/visita/' + infoContent.id"
            text
            color="primary"
          >
            Ver mas
          </v-btn>
        </v-card-actions>
      </v-card>
    </gmap-info-window>
    <GmapMarker
      :id="'visita' + visita.id"
      v-for="(visita, index) in visitasDelDia"
      :key="index"
      :position="{ lat: visita.lat, lng: visita.lng }"
      :clickable="true"
      :draggable="false"
      :icon="fnGetIcon(visita.motivo_de_visita_id)"
      @click="toggleInfo(visita), (flagCurrentIndex = index)"
      @mouseover="fnDrawPolyPath(index)"
    ></GmapMarker>
  </GmapMap>
  <!-- :key="lat" -->
</template>
<script>
import moment from 'moment';
import { gmapApi } from 'vue2-google-maps';
import { mapActions } from 'vuex';
export default {
  computed: {
    google() {
      return new gmapApi();
    },
    flagNextVisita() {
      if (this.visitasDelDia[this.flagCurrentIndex + 1] != undefined) {
        return false;
      } else return true;
    },
    flagPrevVisita() {
      if (this.visitasDelDia[this.flagCurrentIndex - 1] != undefined) {
        return false;
      } else return true;
    },
  },
  name: 'mapa',
  data() {
    return {
      firstDay: moment().startOf('day'),
      lastDay: moment().endOf('day'),
      gmapCenter: { lat: 14.599251, lng: -90.55084 },
      flagCurrentIndex: 0,
      //
      figura_camino_polyline: null,
      poplyLineFlag: false,
      deliveryPath: [],
      deliveryPathColor: '#4DD0E1',
      //
      visitasDelDia: [],
      // place detail
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  methods: {
    ...mapActions('Visitas', {
      findVisitas: 'find',
    }),
    fnGetDate(date) {
      return date ? moment(date).utc().format('DD-MMMM  HH:mm a') : '';
    },
    fnGetColor(motivo) {
      return this.$store.state.visitas.filter(
        (store_visita) => store_visita.id == motivo
      )[0].textColorClass;
    },
    fnGetIcon(motivo) {
      return this.$store.state.visitas.filter(
        (store_visita) => store_visita.id == motivo
      )[0].marker;
    },
    // polyline and get marker info

    toggleInfo(marker) {
      this.infoPosition = { lat: marker.lat, lng: marker.lng };
      this.infoContent = marker;
      if (this.infoCurrentKey == marker.id) {
        // this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
        this.infoCurrentKey = marker.id;
      }
    },
    fnDrawPolyPath(index) {
      this.poplyLineFlag = true;
      this.figura_camino_polyline =
        this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW;

      if (this.visitasDelDia[index + 1] != undefined) {
        this.deliveryPath = [];
        this.deliveryPath.push({
          lat: this.visitasDelDia[index].lat,
          lng: this.visitasDelDia[index].lng,
        });
        this.deliveryPath.push({
          lat: this.visitasDelDia[index + 1].lat,
          lng: this.visitasDelDia[index + 1].lng,
        });
      }
    },
    fnNextVisita() {
      if (this.visitasDelDia[this.flagCurrentIndex + 1] != undefined) {
        this.flagCurrentIndex += 1;
        this.gmapCenter.lat = this.visitasDelDia[this.flagCurrentIndex].lat;
        this.gmapCenter.lng = this.visitasDelDia[this.flagCurrentIndex].lng;

        this.toggleInfo(this.visitasDelDia[this.flagCurrentIndex]);
        this.fnDrawPolyPath(this.flagCurrentIndex);
      }
    },
    fnPrevVisita() {
      if (this.visitasDelDia[this.flagCurrentIndex - 1] != undefined) {
        this.flagCurrentIndex -= 1;
        this.gmapCenter.lat = this.visitasDelDia[this.flagCurrentIndex].lat;
        this.gmapCenter.lng = this.visitasDelDia[this.flagCurrentIndex].lng;

        this.toggleInfo(this.visitasDelDia[this.flagCurrentIndex]);
        this.fnDrawPolyPath(this.flagCurrentIndex);
      }
    },
    getParams() {
      const params = {};
      params['fecha'] = {
        $gte: this.firstDay,
        $lte: this.lastDay,
      };

      params['$limit'] = 1500;

      /** parametrización de supervisoras asignadas a usuario administrativo */
      if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
        params['sector'] = {
          $in: this.$store.state.auth.payload.codigos_supervisoras,
        };
      }
      /** parametrización de supervisoras asignadas a usuario administrativo */
      return params;
    },
  },

  mounted() {
    this.findVisitas({
      query: this.getParams(),
    }).then((result) => {
      this.visitasDelDia = result.data;
    });
    /**
     * escuchar entrega
     */
    const { Visitas } = this.$FeathersVuex.api;
    Visitas.on('created', (visita) => {
      visita.id_consejera = visita.consejera;
      visita.id_usuario = visita.supervisora;
      this.visitasDelDia.push(visita);
    });
  },
};
</script>
<style>
.marker-label {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 5px;
  border-radius: 20px;
  color: #ffff !important;
  font-size: 10px;
  word-break: keep-all;
  width: 290px;
  top: 60px;
  left: -140px;
  cursor: wait;
  z-index: 9999;
}
.marker-label:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
</style>
